<template>
  <div class="appointmentList-view">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="表单列表" name="first"> <addForm v-if="activeName == 'first'" /> </el-tab-pane>
      <el-tab-pane label="基础设置" name="second"> <formSetting v-if="activeName == 'second'" /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import addForm from "./components/advancedForm/addForm";
import formSetting from "./components/advancedForm/formSetting";

export default {
  name: "appointmentList",
  components: {
    addForm,
    formSetting,
    
  },
  data() {
    return {
      activeName:'first'
    };
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>