<template>
  <div class="mt20">
    <div class="flex-a-c mb20">
      <div class="w100 text-align-right mr30">表单模块名称</div>
      <el-input class="w200 textc" 
                v-model="title"
                placeholder="联系我们" 
                maxlength="6"></el-input>
    </div>
    <div class="flex-a-c mb20">
      <div class="w100 text-align-right mr30">表单介绍语</div>
      <el-input class="w600" 
                type="textarea" 
                v-model="desc" 
                placeholder="若您对我们有更好的建议或意见,您可以点击下方提交反债,提出您宝贵的建议,感谢你的支持,我们收到后将第一时间与您联系！" 
                :autosize="{ minRows: 3, maxRows: 4}"
                maxlength="60"></el-input>
    </div>
    <div class="flex-a-c mb20">
      <div class="w100 text-align-right mr30">按钮名称</div>
      <el-input class="w200 textc" 
                type="text" 
                placeholder="提交反馈" 
                v-model="button" 
                maxlength="6"></el-input>
    </div>
    <el-button class="v-primary ml10"
                type="primary"
                @click="saveSetting"
                >发布表单</el-button
              >
  </div>
</template>

<script>

import {
  updateSetting,
  querySetting
} from "@/api/companyManage.js";

export default {
  name: "appointmentList",
  
  data() {
    return {
      title:'',
      button:'',
      desc:''
    };
  },
  mounted(){
    this.querySetting()
  },
  methods: {
    saveSetting(){
      if(!this.title || !this.button || !this.desc){
        return this.$message.error('请完整填写基础设置')
      }
      let data = {
        title:this.title,
        button:this.button,
        state:1,
        desc:this.desc
      }
      updateSetting(data)
      .then(res=>{
        if(res.code == 200){
          this.$message.success('保存成功')
        }else{
          this.$message.error('保存失败')
        }
      })
      .catch(err=>{
        // this.$message.error('保存失败')
      })
    },
    querySetting(){
      let data = {
        state:1
      }
      querySetting(data)
      .then(res=>{
        console.log(res)
        if(res.data ){
          this.title = res.data?.title
          this.button = res.data?.button
          this.desc = res.data?.desc
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

</style>